<template>    
    <div>
        <div id="etablissement_adresse" v-if="adresse">
            <div class="form-group">
                <label for="etablissement_adresse_street">Rue et n° :</label> 
            <input type="text" id="etablissement_adresse_street" name="etablissement[adresse][street]" class="form-control" v-model="adresseModel.street">
            </div>
            <div class="form-group">
                <label for="etablissement_adresse_city">Ville</label> 
                <input type="text" id="etablissement_adresse_city" name="etablissement[adresse][city]" class="form-control" v-model="adresseModel.city">
            </div>
            <div class="form-group">
                <label for="etablissement_adresse_postal_code">Code postal</label> 
                <input type="text" id="etablissement_adresse_postal_code" name="etablissement[adresse][postal_code]" class="form-control" v-model="adresseModel.postal_code">
            </div>
            <div class="form-group">
                <label for="etablissement_adresse_infos">Infos</label> 
                <input type="text" id="etablissement_adresse_infos" name="etablissement[adresse][infos]" class="form-control" v-model="adresseModel.infos">
            </div>
            <div class="form-group">
                <label for="etablissement_adresse_region">Region (texte libre)</label> 
                <input type="text" id="etablissement_adresse_region" name="etablissement[adresse][region]" class="form-control" v-model="adresseModel.region">
            </div>
            <div class="form-group">
                <label for="tablissement[adresse][region_o]">Region Administrative</label>
                <select name="" id="" v-model="adresseModel.region_o" class="form-control">
                    <option  v-for="region in regions" :key="region.id" :value="region">{{ region.libelle }}</option>
                </select>                
            </div>            
        </div>        
    </div>
</template>

<script>
import axios from 'axios';


export default{
    name: 'AdresseHelper',
    emits: ['update:adresse'],
    props: {
        adresse: Object,
    },
    data: function(){
        return {                  
            regions: []            
        };
    },
    computed: {
        adresseModel: {
            get () { return this.adresse },
            set (value) {                 
                this.$emit('update:adresse', value) 
            },
        },
    },
    mounted() {

        axios.get(process.env.VUE_APP_API + '/web/regions')
            .then((response) => {
                this.regions = response.data;
            })
            .catch((error)  => {
                // handle error
                console.log('ERROR', error);
            })             
    },
};
</script>

<style scoped>

</style>