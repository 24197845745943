<template>
    <div>
        <div>
            <div class="form-group">        
                <label for="">Libelle</label>                
                <input type="text" v-model="v$.libelle.$model" class="form-control" :class="{ 'form-error': v$.libelle.$errors.length }">
                <div class="input-errors" v-for="(error, index) of v$.libelle.$errors" v-bind:key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="form-group">
                <label for="">Url</label>
                <input type="text" v-model="v$.url.$model" class="form-control"  :class="{ 'form-error': v$.url.$errors.length }">
                <div class="input-errors" v-for="(error, index) of v$.url.$errors" v-bind:key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="text-right">
                <button v-on:click="addIcal()" class="btn btn-primary">Ajouter</button>
            </div>
        </div>        
    </div>
</template>

<script>
import { required, minLength, url } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default{
    name: 'IcalHelper',
    setup () {
        return { v$: useVuelidate() }
    },
    components: {},
    methods : {
        isInError(field) {
            return (this.errorsId.indexOf(field) < 0) ? false : true;
        },
        addIcal() {
            this.v$.$touch()

            if( !this.v$.$invalid){
                this.$emit('add-ical', {
                    libelle: this.libelle,
                    url: this.url
                });

                this.libelle = ""
                this.url = ""

                this.v$.$reset()
            }
        },        
    },
    data: function(){
        return {
            libelle: '',
            url: '',
        };
    },
    validations() {
        return {
            libelle: { 
                required,
                minLength: minLength(4)
            },
            url: { 
                required,
                url
            },
        }
    }
};
</script>

<style scoped>

</style>