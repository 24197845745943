<template>
<div>    
    <div v-if="videoid && showPlayer">
        <iframe :src="'https://player.vimeo.com/video/'+ videoVimeoID " frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        <p class="text-right">
            <button v-on:click="deleteVideo()" class="btn btn-delete"><i class="fas fa-trash-alt"></i></button>
        </p>
    </div>
    
    <div class="loader" v-if="showLoader">
        <div class="progress-bar" v-bind:style="{ width: percentage + '%' }"></div>       
    </div>
    <div v-if="showForm">
        <input type="file" ref="file" @change="selectFile" class="btn btn-primary">    
    </div>
    <div>{{ message }}</div>
</div>
</template>

<script>
import axios from 'axios';
import * as tus from "tus-js-client";

export default{
    name: 'VimeoUploader',
    props: [
        'uploadurl',
        'videoid'      
    ],
    methods : {
        selectFile() {
            this.selectedFiles = this.$refs.file.files;
            let file = this.selectedFiles[0]            
            this.showForm = false;
            this.showLoader = true;
            this.message = "Création de la vidéo."
            axios.post(this.uploadurl, {"size": file.size})
                .then((response) => {
                    // handle success                    
                    this.message = "Upload de la vidéo"
                    this.vimeoUploadUrl = response.data.vimeo.body.upload.upload_link;
                    this.$emit('add-video', response.data.document)
                    // this.videoid = response.document.fichier
                    // var uri = response.data.body.uri
                    // this.videoid = uri.replace('/videos/', '');                    
                    
                    var upload = new tus.Upload(file, {
                        uploadUrl: this.vimeoUploadUrl,
                        onError: (error) => {
                            console.log("Failed because: " + error)
                            this.message = error;
                        },
                        onProgress: (bytesUploaded, bytesTotal) => {
                            
                            this.percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                        },
                        onSuccess: () => {
                            console.log("SUCCESS Download %s from %s", upload.file.name, upload.url)
                            this.showLoader = false;
                            this.message = "Success, vidéo en cours d'encodage";
                            this.showPlayer = true;
                        }
                    })
                    
                    // Start the upload
                    upload.start()
                })
                .catch((error)  => {
                    // handle error
                    console.log(error);
                    this.message = "ERREUR :"+ error
                    this.showPlayer = false;
                })
        },
        deleteVideo() {
            this.$emit('delete-video')            
        }
    },
    mounted() {
        if(this.videoid) this.showPlayer = true;     
    },
    data() {
        return {
            showForm: true,
            vimeoUploadUrl: "",
            filerecords: [],
            showLoader: false,
            percentage: 0,
            message: "",
            showPlayer: false,
            uploadedID: ""
        };
    },
    computed: {
        videoVimeoID() {
            if(this.uploadedID) return this.uploadedID
            else return this.videoid
        }
    }
}; // 
</script>

<style lang="scss" scoped>
    .loader{
        height: 30px;
        width: 100%;
        background-color:  #FFF4E6;
        .progress-bar{
            height: 30px;
            width: 50%;
            background-color: #575355;
            z-index: 2;
        }
    }

    iframe{
        width: 100%;
    }
</style>