<template>
    <div class="title-flex">
        <h1>{{ etablissement.titre }}</h1>
        <p class="m-b-lg"><strong>Lien ical</strong> : <a :href="icalLink" target="_blank">{{ icalLink }}</a></p>
    </div>
    <div class="row">
        <div class="col-lg-8">
            <div class="ibox">
                <div class="ibox-content">
                    <h2>Informations</h2>
                    <div class="form-group">
                        <label for="">Titre :</label>
                        <input v-model="etablissement.titre"  class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="">Description :</label>
                        <ckeditor :editor="editor" :config="editorConfig" v-model="etablissement.description"></ckeditor>
                    </div>
                    <div class="form-group">
                        <label for="">Site Web :</label>
                        <input v-model="etablissement.site_web" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="">Email :</label>
                        <input v-model="etablissement.email" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="">Téléphone :</label>
                        <input v-model="etablissement.telephone" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="">BIC :</label>
                        <input v-model="etablissement.bic" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="">IBAN :</label>
                        <input v-model="etablissement.iban" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="">SIREN :</label>
                        <input v-model="etablissement.siren" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="">N° TVA Intracommunautaire :</label>
                        <input v-model="etablissement.tva_intra" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="">Autoriser le retrait des produits à l'établissement :</label>                        
                        <select  v-model="etablissement.has_retrait" class="form-control">
                          <option value="true">Oui</option>
                          <option value="false">Non</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="">Délai de retrait des produits à l'établissement :</label>
                        <input v-model="etablissement.retrait_delai" class="form-control" />
                    </div>

                    <div class="m-t-lg text-right">
                        <button v-on:click="saveData()" class="btn btn-primary">Valider</button>
                    </div>
                </div>
            </div>
            <div class="ibox">
                <div class="ibox-content">
                    <h2>Adresse</h2>
                    <AdresseHelper v-model:adresse="etablissement.adresse"/>
                    <div class="m-t-lg text-right">
                        <button v-on:click="saveData()" class="btn btn-primary">Valider</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <!-- IMAGES -->
            <div class="ibox">
                <div class="ibox-content">
                    <div class="title-flex">
                        <h2>Images</h2>
                        <span class="m-b-lg">
                            <file-uploader ref="uno" :uploadurl="uploadImageUrl" @add-document="addImage" :name="'image'" />
                        </span>
                    </div>
                    <div class="ibox-image" :class="{ 'active': image.id == etablissement.image.id, '': image.id != etablissement.image.id}" v-for="image in mergedGallery" :key="image.id">
                        <img :src="image.full_path" width="200" alt="">
                        <div class="buttons">
                            <button class="btn" :class="{ 'btn-secondary': image.id == etablissement.image.id, 'btn-default': image.id != etablissement.image.id}" @click="setDefaultImage(image)">Image Principale</button>
                            <button class="btn" :class="{ 'btn-secondary': image.id == etablissement.image_background?.id, 'btn-default': image.id != etablissement.image_background?.id}" @click="setBackgroundImage(image)">Image Background</button>
                            <button class="btn btn-delete" @click="removeDocument(image)" title="Supprimer"><i class="fas fa-trash-alt"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- VIDEO -->
            <div class="ibox">
                <div class="ibox-content">
                    <h2>Vidéo</h2>
                    <VimeoUploader
                      :uploadurl="uploadVideoUrl"
                      :videoid="etablissement.video?.fichier"
                      @add-video="addVideo"
                      @delete-video="removeVideo" />
                </div>
            </div>

             <!-- CGV -->
            <div class="ibox">
                <div class="ibox-content">
                    <div class="title-flex">
                        <h2>CGV</h2>
                        <span class="m-b-lg">
                            <file-uploader ref="dos" :uploadurl="uploadImageUrl"
                                :name="'cgv'"
                                :extensions="'pdf'"
                                :accept="'application/pdf'"
                                @add-document="addCGV" />
                        </span>
                    </div>
                    <div class="ibox-item">
                        <a :href="etablissement.cgv?.full_path">{{ etablissement.cgv?.full_path }}</a>
                    </div>
                </div>
            </div>

            <!-- ICAL -->
            <div class="ibox">
                <div class="ibox-content">
                    <h2>Ical</h2>
                    <div class="m-b-lg" v-for="(ical, index) in etablissement.ical_links" v-bind:key="index">
                        <div class="ibox-item">
                            <p><strong>{{ ical.libelle }}</strong><br />
                            <small>{{ ical.url }}</small></p>
                            <button v-on:click="removeIcal(ical)" class="btn btn-delete" title="Supprimer"><i class="fas fa-trash-alt"></i></button>
                        </div>
                    </div>
                    <IcalHelper @add-ical="addIcal"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { h } from 'vue'

import FileUploader from "@/components/FileUploader";
import VimeoUploader from "@/components/VimeoUploader";
import IcalHelper from "@/components/IcalHelper";
import AdresseHelper from "@/components/AdresseHelper";
import useVuelidate from '@vuelidate/core'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

export default {
  name: "Etablissement",
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      etablissement: {},
      editor: ClassicEditor,
      editorConfig : {
          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
      }
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    // $route: "fetchData",
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      axios
        .get(
          process.env.VUE_APP_API + "/producteur/etablissement/" +
            this.$route.params.uuid,
          {}
        )
        .then((resp) => {
          loading.close();
          this.etablissement = resp.data;
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    saveData() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      axios
        .post(
          process.env.VUE_APP_API + "/producteur/etablissement/" +
            this.etablissement.uuid,
          this.etablissement
        )
        .then((resp) => {
          loading.close();
          this.etablissement = resp.data;
          this.$notify({
            title: 'Etablissement enregistré',
            message: h('i', { style: 'color: teal' }, '')
          });
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    addCGV(doc) {
      this.etablissement.cgv = doc;
    },
    addImage(doc){
      this.etablissement.gallery.push(doc)
      this.saveData()
    },
    removeDocument(doc){
      this.etablissement.gallery = this.etablissement.gallery.filter(function( obj ) {
          return obj.id !== doc.id;
      });
      this.saveData()
    },
    addVideo(doc){
      this.etablissement.video = doc;
      this.saveData()
    },
    removeVideo(){
      this.etablissement.video = null;
      this.saveData()
    },
    setDefaultImage(image){
      this.etablissement.image = image
      this.saveData()
    },
    setBackgroundImage(image){
      this.etablissement.image_background = image
      this.saveData()
    },
    setAdresse(adresse){
      this.etablissement.adresse = adresse
      this.saveData()
    },
    addIcal(ical){
      if(!this.etablissement.ical_links) this.etablissement.ical_links = []
      this.etablissement.ical_links.push(ical)
      this.saveData()
    },
    removeIcal(ical){      
      this.etablissement.ical_links = this.etablissement.ical_links.filter(function( obj ) {
          return obj.id !== ical.id &&  obj.url !== ical.url;
      });      
      this.saveData()
    }
  },
  computed: {
    uploadVideoUrl() {
      return process.env.VUE_APP_API + '/producteur/document/upload/video'
    },
    uploadImageUrl() {
      return process.env.VUE_APP_API + '/producteur/document/upload/image'
    },
    icalLink() {
      return process.env.VUE_APP_TRINCH + '/etablissement/ical/' + this.etablissement.uuid
    },
    mergedGallery(){
      let gal = [];
      if(this.etablissement.gallery) gal = [...this.etablissement.gallery]
      if(this.etablissement.image) {
        if(gal.map( e => { return e.id }).indexOf(this.etablissement.image.id) < 0){
          gal.unshift(this.etablissement.image)
        }
      }
      return gal
    }
  },
  components: {
    FileUploader,
    VimeoUploader,
    IcalHelper,
    AdresseHelper,
    ckeditor: CKEditor.component
  },
};
</script>